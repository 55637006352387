<template>
<div class="animated fadeIn">
  <div id="snackbar"></div>
  <b-row>
    <b-col cols="12" xl="8">
      <transition name="slide">
       <b-card>
      <b-card-header>
        <i class="icon-menu mr-1"></i>List of Application Users
        <div class="card-header-actions">
          
        </div>
      </b-card-header>
      <b-card-body>
        <v-client-table :columns="columns" :data="data" :options="options" :theme="theme" id="dataTable">
          
          <div slot="IsActive" slot-scope="props">
            <c-switch class="mx-1" color="primary" v-model="props.row.IsActive" variant="3d" label v-bind="labelIcon" @change.native="changeStatus(props.row.ID,props.row.IsActive)"/>
          </div>
          
          <div slot="ID" slot-scope="props">
            <i class="fa fa-edit" @click="editUser(props.row.ID)"></i>
          </div>
        </v-client-table>
      </b-card-body>
      </b-card>
      </transition>
    </b-col>
  </b-row>
  </div>
</template>

<script>
import Vue from "vue";
import UserService from "@/services/UserService";
import MasterService from "@/services/MasterService";
import { ClientTable, Event } from "vue-tables-2";
import { Switch as cSwitch } from "@coreui/vue";
Vue.use(ClientTable);
export default {
  name: "AppUsers",
  components: {
    cSwitch,
    ClientTable,
    Event
  },
  data: () => {
    return {
      columns: ["UserName", "IsActive", "ID"],
      data: [],
      options: {
        headings: {
          UserName: "User Name",
          IsActive: "Status",
          ID: "Edit"
        },
        sortable: ["UserName"],
        filterable: ["UserName"],
        sortIcon: {
          base: "fa",
          up: "fa-sort-asc",
          down: "fa-sort-desc",
          is: "fa-sort"
        },
        pagination: {
          chunk: 5,
          edge: false,
          nav: "scroll"
        }
      },
      useVuex: false,
      theme: "bootstrap4",
      template: "default",
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      }
    };
  },
  mounted() {
    this.getUsers();
  },
  methods: {
    getUsers: function() {
      this.data = [];
      UserService.getUsers()
        .then(response => {
          const { data } = response;
          if (data.Status && data.UserList.length > 0) {
            this.data = data.UserList.filter(
              user => user.Role == "User" && user.UserName.length > 0
            ).reverse();
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          console.log("Catch on AddUser-getRoles Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    editUser: function(UserID) {
      this.$router.push({
        path: "/user-management/add-user",
        query: { UserID }
      });
    },
    changeStatus: function(ID, valueStatus) {
      // let res = await MasterService.activateOrDeactivate(ID, "Users");
      let payload = {
        TableName: "Users",
        ID
      };
      MasterService.activateOrDeactivate(payload)
        .then(response => {
          const { data } = response;
          if (data.Status) {
            this.data.map(x => {
              if (x.ID == ID) {
                x.IsActive = valueStatus;
              }
            });
          }
        })
        .catch(error => {
          console.log("Catch on AddUser-changeStatus Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    }
  },
  // Show Error Message
  showMessageSnackbar: function(message) {
    let x = document.getElementById("snackbar");
    x.className = "show";
    x.innerHTML = message;
    setTimeout(function() {
      x.className = x.className.replace("show", "");
    }, 3000);
  }
};
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
</style>
